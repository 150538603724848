import {BrowserRouter as Router} from 'react-router-dom';
import Pages from './navigation/pages';
import './App.css';
import {Web3ContextProvider} from './context/web3-context';

import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

function ScrollToTop() {
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div className="App">
      <Web3ContextProvider>
        <Router>
          <ScrollToTop />
          <Pages />
        </Router>
      </Web3ContextProvider>
    </div>
  );
}

export default App;
