import React from 'react';
import {Link} from 'react-router-dom';
import {DiscordLogo, OpenseaLogo, TwitterLogo} from '../../assets/icons/logos';
import clubs_court_icon from '../../assets/images/clubs-court-icon.jpg';
import diamonds_court_icon from '../../assets/images/diamonds-court-icon.jpg';
import hearts_court_icon from '../../assets/images/hearts-court-icon.jpg';
import logo from '../../assets/images/logo.jpg';
import outliers_court_icon from '../../assets/images/outliers-court-icon.jpg';
import spades_court_icon from '../../assets/images/spades-court-icon.jpg';
import {DISCORD_URL, OPENSEA_URL, TWITTER_URL} from '../../constants';
import './index.css';

const Navbar = () => {
  return (
    <>
      <div className="navbar-container">
        <div className="navbar-emblem">
          <a href="/">
            <img src={logo} alt="Court of Degeneracy Logo" />
          </a>
        </div>
        <div className="navbar-items">
          <div className="courts">
            <Link to={`/hearts/`} className="hearts">
              <img src={hearts_court_icon} alt="Court of Hearts" />
            </Link>
            <Link to={`/spades/`} className="spades">
              <img src={spades_court_icon} alt="Court of Spades" />
            </Link>
            <Link to={`/diamonds/`} className="diamonds">
              <img src={diamonds_court_icon} alt="Court of Diamonds" />
            </Link>
            <Link to={`/clubs/`} className="clubs">
              <img src={clubs_court_icon} alt="Court of Clubs" />
            </Link>
            <Link to={`/outliers/`} className="outliers">
              <img src={outliers_court_icon} alt="Court of Outliers" />
            </Link>
          </div>
          <a href={DISCORD_URL} target="_blank" rel="noopener noreferrer">
            <DiscordLogo />
          </a>
          <a href={TWITTER_URL} target="_blank" rel="noopener noreferrer">
            <TwitterLogo />
          </a>
          <a href={OPENSEA_URL} target="_blank" rel="noopener noreferrer">
            <OpenseaLogo />
          </a>
          <Link to={`/art/`} className="art-link">
            <p className="desktop">Art Collection</p>
            <p className="mobile">Art</p>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Navbar;
