import React from 'react';
import dodLogo from '../../assets/images/logo.jpg';
import trait1 from '../../assets/images/trait1.jpg';
import trait2 from '../../assets/images/trait2.jpg';
import trait3 from '../../assets/images/trait3.jpg';
import trait4 from '../../assets/images/trait4.jpg';
import './index.css';

const Traits = () => {
  return (
    <>
      <div className="trait-container" id="traits">
        <p className="text">
          <h2 className="title">Traits by Top NFT Artists</h2>
          All traits listed below will have 8 copies each in the collection of 10,000 total nfts, making them extremely
          rare. Court of Degeneracy has been given the rights to use all of these pieces of art either directly by the
          artists or because their art is CC0. Many of these traits will provide various forms of utility in the future.
        </p>
        <div className="body">
          <div>
            <h2>Accessories</h2>
            <p className="text">
              Ascension |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/ZachWinterton">
                Zachary Winterton
              </a>
            </p>
            <p className="text">
              Aughostus |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/mumbot">
                MUMBOT
              </a>
            </p>
            <p className="text">
              Broken Heart |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/CullenColors">
                CullenColors
              </a>
            </p>
            <p className="text">
              Cozomo Card |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/WesHenryArt">
                Wes Henry
              </a>
            </p>
            <p className="text">
              CrypToadz |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/gremplin">
                gremplin
              </a>
            </p>
            <p className="text">
              Damon |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/terrelldom">
                Terrel Jones
              </a>
            </p>
            <p className="text">
              ETH |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/niftyjutsu">
                niftyjutsu
              </a>
            </p>
            <p className="text">
              Fake Internet Money |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/steviepxyz">
                steviep
              </a>
            </p>
            <p className="text">
              FAKEROTHKO |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/Vince_Van_Dough">
                VVD
              </a>
            </p>
            <p className="text">
              Grifter |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/XCOPYART">
                XCOPY
              </a>
            </p>
            <p className="text">
              Joker |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/AnaKim_art">
                Ana Kim
              </a>
            </p>
            <p className="text">
              Let's Walk |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/deekaymotion">
                DeeKay
              </a>
            </p>
            <p className="text">
              Lily |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/ryan_talbot_">
                Ryan Talbot
              </a>
            </p>
            <p className="text">
              Meet Me At The Fridge |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/GuidoDisalle">
                Guido
              </a>
            </p>
            <p className="text">
              MINDDS |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/Dario_Desiena">
                Dario De Siena
              </a>
            </p>
            <p className="text">
              Modern Renaissance Frens |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/carav4ggio">
                Caravaggio
              </a>
            </p>
            <p className="text">
              Poplin |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/PopWonderNFT">
                Pop Wonder
              </a>
            </p>
            <p className="text">
              Queen Of Hearts |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/GabrielJWeis">
                Gabe Weis
              </a>
            </p>
            <p className="text">
              Reaper |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/otherworld_xx">
                Otherworld
              </a>
            </p>
            <p className="text">
              sail-o-bot |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/cheyuwu345">
                Che-Yu Wu
              </a>
            </p>
            <p className="text">
              Satoshi's Nuts |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/painter_crypto">
                Cryptopainter
              </a>
            </p>
            <p className="text">
              Tank Dog |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/WoodenCyclops">
                Wooden Cyclops
              </a>
            </p>
            <p className="text">
              UFO |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/GrantYun2">
                Grant Riven Yun
              </a>
            </p>
            <p className="text">
              Ugly Kitty |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/sabet">
                Sabet
              </a>
            </p>
            <p className="text">
              Wes World Collection |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/WesHenryArt">
                Wes Henry
              </a>
            </p>

            <h2>Backgrounds</h2>
            <p className="text">
              #freehawaiiphoto |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/cathsimard_">
                CATH Simard
              </a>
            </p>
            <p className="text">
              Abandon Normal Instruments |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/Reuben_Wu">
                Reuben Wu
              </a>
            </p>
            <p className="text">
              BLeU |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/0xTjo">
                tjo
              </a>
            </p>
            <p className="text">
              Floating World Casino |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/gelo_arch">
                gelo
              </a>
            </p>
            <p className="text">
              Night Visions |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/jakejfried">
                Jake Fried
              </a>
            </p>
            <p className="text">
              Opportunity |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/dangiuz">
                Dangiuz
              </a>
            </p>
            <p className="text">
              Swervewolf |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/96StudioX">
                Barry Sutton
              </a>
            </p>
            <p className="text">
              THE END OF THE BEGINNING |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/jacobriglin">
                Jacob Riglan
              </a>
            </p>
            <p className="text">
              The Joker's Revenge |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/photonisdead">
                pho
              </a>
            </p>
            <p className="text">
              Patrick Amadon |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/patrickamadon">
                Patrick Amadon
              </a>
            </p>

            <h2>Left Hand Traits</h2>
            <p className="text">
              Glitch Sword |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/kingxerox5">
                King Xerox
              </a>
            </p>
            <p className="text">
              Hopium |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/osf_nft">
                OSF
              </a>
            </p>

            <h2>Neckwear</h2>
            <p className="text">
              Pepe |{' '}
              <a target="_blank" rel="noreferrer" href="https://twitter.com/ScrillaVentura">
                RARE SCRILLA
              </a>
            </p>
          </div>
          <div className="images">
            <img src={trait1} alt="trait 1" />
            <img src={trait2} alt="trait 2" />
            <img src={trait3} alt="trait 3" />
            <img src={trait4} alt="trait 4" />
          </div>
        </div>
      </div>

      <hr className="gray-line mb-5"></hr>

      <div className="tail">
        <div className="tail-1">
          <a href="/">
            <img src={dodLogo} alt="Court of Degeneracy Logo" />
          </a>
        </div>
        <div className="tail-2">Copyright &copy; 2023 Court of Degeneracy. All Rights Reserved.</div>
      </div>
    </>
  );
};

export default Traits;
