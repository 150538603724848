import {Link} from 'react-router-dom';
import clubs_header from '../../assets/images/clubs-header.jpg';
import clubs1 from '../../assets/images/clubs1.jpg';
import clubs2 from '../../assets/images/clubs2.jpg';
import clubs3 from '../../assets/images/clubs3.jpg';
import map_clubs from '../../assets/images/map-clubs.jpg';
import map_diamonds from '../../assets/images/map-diamonds.jpg';
import map_hearts from '../../assets/images/map-hearts.jpg';
import map_outliers from '../../assets/images/map-outliers.jpg';
import map_spades from '../../assets/images/map-spades.jpg';
import diamonds_header from '../../assets/images/diamonds-header.jpg';
import diamonds1 from '../../assets/images/diamonds1.jpg';
import diamonds2 from '../../assets/images/diamonds2.jpg';
import diamonds3 from '../../assets/images/diamonds3.jpg';
import hearts_header from '../../assets/images/hearts-header.jpg';
import hearts1 from '../../assets/images/hearts1.jpg';
import hearts2 from '../../assets/images/hearts2.jpg';
import hearts3 from '../../assets/images/hearts3.jpg';
import outliers_header from '../../assets/images/outliers-header.jpg';
import outliers1 from '../../assets/images/outliers1.jpg';
import outliers2 from '../../assets/images/outliers2.jpg';
import outliers3 from '../../assets/images/outliers3.jpg';
import spades_header from '../../assets/images/spades-header.jpg';
import spades1 from '../../assets/images/spades1.jpg';
import spades2 from '../../assets/images/spades2.jpg';
import spades3 from '../../assets/images/spades3.jpg';
import './index.css';

const container = (inner) => {
  return (
    <div className="courts-container">
      <div className="courts">{inner}</div>
    </div>
  );
};

const Clubs = () => {
  return container(
    <>
      <div className="inner">
        <div>
          <div className="header">
            <img src={clubs_header} alt="clubs" />
          </div>
          <p className="text">
            The Clubs moved their court to the secluded Forgotten Forest after the great quake. The laid back Clubs are
            some of the most epic partiers in the realm. Music, meditation, and getting REKT on hopium is the name of
            the game among their degenerates and vagabonds. The three main houses throw constant parties in their
            castles vying for their peoples' favor.
          </p>
          <p className="text">
            "Three spiders lay claim to the throne in the Forgotten Forest. What a tangled web they weave. Neon thread
            and thrumming strings keep a weary traveler all the wearier. What happens in the Court of Clubs, stays
            there."
          </p>
          <p className="text">
            <table>
              <tr>
                <th>Traits:</th>
                <td>Spiked Club, Champagne, Disco Ball</td>
              </tr>
              <tr>
                <th>Attributes:</th>
                <td>Zen, Calm, Truthful, Spacey</td>
              </tr>
              <tr>
                <th>Landmarks:</th>
                <td>Gala's Castle, Algo's Castle, Fete's Castle</td>
              </tr>
              <tr>
                <th>Current Ally:</th>
                <td>
                  <Link to={`/hearts/`}>Hearts</Link>
                </td>
              </tr>
              <tr>
                <th>Current Enemy:</th>
                <td>
                  <Link to={`/diamonds/`}>Diamonds</Link>
                </td>
              </tr>
            </table>
          </p>
          <div className="map">
            <img src={map_clubs} alt="clubs map" />
          </div>
        </div>
        <div className="samples">
          <img src={clubs1} alt="court 1" />
          <img src={clubs2} alt="court 2" />
          <img src={clubs3} alt="court 3" />
        </div>
      </div>
    </>
  );
};

const Diamonds = () => {
  return container(
    <>
      <div className="inner">
        <div>
          <div className="header">
            <img src={diamonds_header} alt="diamonds" />
          </div>
          <p className="text">
            The Court of Diamonds is the most studious and stoic of the courts. Glass palaces, crystal casinos, and
            grand libraries attract travelers from across the realm. The Diamonds are the great philosophers of the
            kingdom. They revel in trickery, illusion, and outwitting their opponents. Many of the kingdom's artifacts
            have been hidden away deep in the sea of the Court of Diamonds.
          </p>
          <p className="text">
            "The Diamonds are bold, tricky, and do not mince words. They are the keepers of knowledge. Any question can
            be answered at their great guarded libraries. They have little patience for sloth and slumber."
          </p>
          <p className="text">
            <table>
              <tr>
                <th>Traits:</th>
                <td>Kraken, Trident, Ice Shirt</td>
              </tr>
              <tr>
                <th>Attributes:</th>
                <td>Reflective, Intellectual, Calm, Shrewd, Upfront, Rational</td>
              </tr>
              <tr>
                <th>Landmarks:</th>
                <td>Mises' Library, Impenetrable Gate, Freeze's Lookout</td>
              </tr>
              <tr>
                <th>Current Ally:</th>
                <td>
                  <Link to={`/hearts/`}>Hearts</Link>
                </td>
              </tr>
              <tr>
                <th>Current Enemy:</th>
                <td>
                  <Link to={`/clubs/`}>Clubs</Link>
                </td>
              </tr>
            </table>
          </p>
          <div className="map">
            <img src={map_diamonds} alt="diamonds map" />
          </div>
        </div>
        <div className="samples">
          <img src={diamonds1} alt="court 1" />
          <img src={diamonds2} alt="court 2" />
          <img src={diamonds3} alt="court 3" />
        </div>
      </div>
    </>
  );
};

const Hearts = () => {
  return container(
    <>
      <div className="inner">
        <div>
          <div className="header">
            <img src={hearts_header} alt="hearts" />
          </div>
          <p className="text">
            The Court of the Hearts lives high up in the volcanic Magma Mountains. Life is peaceful and all live in
            harmony. Many travelers visit from far and wide to see the castles of the Court of Hearts, built by the
            first masons banished from the Garden of Good Mornings, a memorial of better times intended to unify the
            kingdom. Community, charity, and honor are the pillars by which the Court of Hearts finds its enduring
            prosperity.
          </p>
          <p className="text">
            "Kindness wins out in the Court of Hearts. Merciless in the defense of their land, they accept all who come
            in peace and utterly destroy those who do not."
          </p>
          <p className="text">
            <table>
              <tr>
                <th>Traits:</th>
                <td>Fire Sword, Teddy Bear, Cupid</td>
              </tr>
              <tr>
                <th>Attributes:</th>
                <td>Loving, Kind, Adventurous, Impulsive, Courteous</td>
              </tr>
              <tr>
                <th>Landmarks:</th>
                <td>Love Hollow, Karibu's Mead Hall, Lava Landing</td>
              </tr>
              <tr>
                <th>Current Ally:</th>
                <td>
                  <Link to={`/diamonds/`}>Diamonds</Link>
                </td>
              </tr>
              <tr>
                <th>Current Enemy:</th>
                <td>
                  <Link to={`/spades/`}>Spades</Link>
                </td>
              </tr>
            </table>
          </p>
          <div className="map">
            <img src={map_hearts} alt="hearts map" />
          </div>
        </div>
        <div className="samples">
          <img src={hearts1} alt="court 1" />
          <img src={hearts2} alt="court 2" />
          <img src={hearts3} alt="court 3" />
        </div>
      </div>
    </>
  );
};

const Spades = () => {
  return container(
    <>
      <div className="inner">
        <div>
          <div className="header">
            <img src={spades_header} alt="spades" />
          </div>
          <p className="text">
            Life is a ruthless competition in the trading city of D'Jin. The surface hosts a court full of intrigue and
            deception. Their people consist of a fashionable royalty, a rich merchant class, and spies around every
            corner, but the real action happens below the city. The sewers of the Court of Spades are a vast underground
            network where vice is currency and commodity. Though the Spades have the smallest army, they hold the most
            power over the Kingdom due to their trading and banking resources.
          </p>
          <p className="text">
            "Two factions vie for control of the Court of Spades in the city of D'Jin: One on the river and one under
            it. Only a court so on the brink of collapse could live like the people of D'Jin"
          </p>
          <p className="text">
            <table>
              <tr>
                <th>Traits:</th>
                <td>Lightning Sword, Poodle, Laptop Computer</td>
              </tr>
              <tr>
                <th>Attributes:</th>
                <td>Regal, Proud, Deceit, Unhinged</td>
              </tr>
              <tr>
                <th>Landmarks:</th>
                <td>The Cathedral, Toxic Tavern, Trader's Alley</td>
              </tr>
              <tr>
                <th>Current Ally:</th>
                <td>
                  <Link to={`/clubs/`}>Clubs</Link>
                </td>
              </tr>
              <tr>
                <th>Current Enemy:</th>
                <td>
                  <Link to={`/diamonds/`}>Diamonds</Link>
                </td>
              </tr>
            </table>
          </p>
          <div className="map">
            <img src={map_spades} alt="spades map" />
          </div>
        </div>
        <div className="samples">
          <img src={spades1} alt="court 1" />
          <img src={spades2} alt="court 2" />
          <img src={spades3} alt="court 3" />
        </div>
      </div>
    </>
  );
};

const Outliers = () => {
  return container(
    <>
      <div className="inner">
        <div>
          <div className="header">
            <img src={outliers_header} alt="outliers" />
          </div>
          <p className="text">
            Chaos rules in the world of the Outliers. Once the battle settled, almost everyone left the Garden of Good
            Mornings, which has since become a small oasis surrounded by desert wasteland. Most of their members are
            Jokers, Sharks, and Whales. Little is known of this mysterious group, and the little we do know is from the
            travels of the great philosopher Cozomus the Elder.
          </p>
          <p className="text">
            "The Court of Outliers is a festival of the untamed. They are individualistic people, quick to act and slow
            to plan. Every day with the Outliers is an adventure."
          </p>
          <p className="text">
            <table>
              <tr>
                <th>Traits:</th>
                <td>All In magic</td>
              </tr>
              <tr>
                <th>Attributes:</th>
                <td>You never know what you are going to get</td>
              </tr>
              <tr>
                <th>Landmarks:</th>
                <td>Desolated Garden of Good Mornings</td>
              </tr>
              <tr>
                <th>Current Ally:</th>
                <td>Unknown</td>
              </tr>
              <tr>
                <th>Current Enemy:</th>
                <td>Unknown</td>
              </tr>
            </table>
          </p>
          <div className="map">
            <img src={map_outliers} alt="outliers map" />
          </div>
        </div>
        <div className="samples">
          <img src={outliers1} alt="court 1" />
          <img src={outliers2} alt="court 2" />
          <img src={outliers3} alt="court 3" />
        </div>
      </div>
    </>
  );
};

const Courts = {Clubs, Diamonds, Hearts, Spades, Outliers};

export default Courts;
