import React, {useContext, useState} from 'react';
import {CSVLink} from 'react-csv';
import {BACKEND_URL, OPENSEA_TOKEN_URL} from '../../constants';
import {Web3Context} from '../../context/web3-context';
import './index.css';

const storageVersion = '1';
const passwordStorageKey = `admin_password_${storageVersion}`;
const openSeaContractABI = require('../../assets/abis/opensea-abi.json');

const allowedAddresses = {
  '0x6b6ae848f555f70944bc99d736fe29fcaecf8b23': true, // JL
  '0xeed4242f735fa70ed1cf30deae41efb793ea01f0': true, // DOD
  '0xf889dfb9353984d3fae769492e8456305475763f': true, // Rylan
};

const Index = () => {
  const {address, connect, connected, web3} = useContext(Web3Context);
  const [error, setError] = useState('');
  const [password, doSetPassword] = useState(localStorage.getItem(passwordStorageKey));
  const [dateValue, setDate] = useState('');
  const [query, setQuery] = useState('');
  const [backendResult, setBackendResult] = useState(undefined);
  const [requestLoading, setRequestLoading] = useState(false);
  const [ethToSend, setETHToSend] = useState('');
  const [openSeaTokenIDToSend, setOpenSeaTokenToSend] = useState('');
  const [ethToSendBorderColor, setETHToSendBorderColor] = useState('');
  const [statuses, setStatuses] = useState({});

  function setPassword(value) {
    localStorage.setItem(passwordStorageKey, value);
    return doSetPassword(value);
  }

  async function sendOpenSeaTokenToAll() {
    if (!openSeaTokenIDToSend) {
      alert('Set TokenID To Send Value First');
      return;
    }
    backendResult.addresses.forEach((record) => {
      if (!statuses[record.address]) {
        sendOpenSeaToken(record.address, record.count);
      }
    });
  }

  async function sendETHToAll() {
    if (!ethToSend) {
      alert('Set ETH To Send Value First');
      return;
    } else if (isNaN(ethToSend)) {
      alert('Invalid ETH To Send Value');
      return;
    }
    backendResult.addresses.forEach((record) => {
      if (!statuses[record.address]) {
        sendETH(record.address, record.count);
      }
    });
  }

  async function sendOpenSeaToken(toAddress, count) {
    if (!openSeaTokenIDToSend) {
      alert('Set TokenID To Send Value First');
      return;
    }

    const newStatusValues = {};
    try {
      const openSeaContract = new web3.eth.Contract(openSeaContractABI, '0x495f947276749ce646f68ac8c248420045cb7b5e');
      const safeTransferFrom = openSeaContract.methods.safeTransferFrom(
        address,
        toAddress,
        openSeaTokenIDToSend,
        count,
        '0x'
      );
      const gasEstimate = await safeTransferFrom.estimateGas({from: address});
      await safeTransferFrom.send({
        from: address,
        gas: gasEstimate,
      });
      newStatusValues[toAddress] = <span style={{color: '#999'}}>Sent</span>;
    } catch (error) {
      let message = 'Error';
      if (error.message && error.message.includes('ONLY_CREATOR_ALLOWED')) {
        message = `Error you don't own Token ID ${openSeaTokenIDToSend}`;
      } else if (error.message) {
        message = `Error sending data: (${error.code}): ${error.message}`;
      } else {
        message = `Error sending data: ${error}`;
      }
      console.error(toAddress, message, error);
      newStatusValues[toAddress] = <div style={{color: 'red', margin: '0 auto 5px'}}>Error {message}</div>;
    }
    setStatuses((statuses) => ({
      ...statuses,
      ...newStatusValues,
    }));
  }

  async function sendETH(address, count) {
    if (!ethToSend) {
      alert('Set ETH To Send Value First');
      return;
    } else if (isNaN(ethToSend)) {
      alert('Invalid ETH To Send Value');
      return;
    }
    web3.eth.sendTransaction(
      {
        to: address,
        from: web3.currentProvider.selectedAddress,
        value: web3.utils.toWei(`${ethToSend * count}`, 'ether'),
      },
      (err, hash) => {
        const newStatusValues = {};
        if (err) {
          console.error(address, err);
          newStatusValues[address] = (
            <div style={{color: 'red', margin: '0 auto 5px'}}>
              Error ({err.code}): {err.message}
            </div>
          );
        } else {
          newStatusValues[address] = <span style={{color: '#999'}}>Sent ({hash})</span>;
        }
        setStatuses((statuses) => ({
          ...statuses,
          ...newStatusValues,
        }));
      }
    );
  }

  async function makeRequest() {
    setError('');
    setStatuses({});
    setBackendResult(undefined);
    setRequestLoading(true);
    try {
      if (!password) {
        setError('Missing Password');
        setRequestLoading(false);
        return;
      }
      const response = await fetch(`${BACKEND_URL}/cod_holders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: query,
          date: dateValue,
          accessCode: password,
        }),
      });
      if (response.status === 401) {
        setError('401 error: Invalid Password');
        setRequestLoading(false);
        return;
      } else if (response.status !== 200) {
        const body = await response.text();
        setError(`${response.status} error: Invalid Response\nBody: ${body}`);
        setRequestLoading(false);
        return;
      }
      const result = await response.json();
      setBackendResult(result);
      setStatuses({});
      setRequestLoading(false);
    } catch (e) {
      setError(`Error requesting data: ${e}`);
      setRequestLoading(false);
    }
  }

  if (connected) {
    if (allowedAddresses[address.toLowerCase()]) {
      return (
        <div className="admin-panel">
          {error && (
            <div className="error">
              <h3>Error</h3>
              <div>{error}</div>
            </div>
          )}
          <div className="admin-password">
            <p>
              <label htmlFor="password">Password:</label>
            </p>
            <input
              id="password"
              type="password"
              value={password}
              style={{width: '180px'}}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="admin-request">
            <p>
              <label htmlFor="date">
                Date: <span>(Eastern Timezone - ie: 2022-07-15 18:00:00 - default is "now")</span>
              </label>
            </p>
            <input
              type="text"
              id="date"
              placeholder="now"
              value={dateValue}
              onChange={(e) => {
                setDate(e.target.value);
                setBackendResult(undefined);
              }}
            />
            <p>
              <label htmlFor="query">
                Query: TokenID or Metadata:{' '}
                <span>(type=value (comma delimited for multiple values) - not case sensitive - one per line)</span>
              </label>
            </p>
            <textarea
              placeholder={'Example:\n\n tokenid=1234,5678\n left hand=photograph\n eyes=blood,chill'}
              type="text"
              id="query"
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
                setBackendResult(undefined);
              }}
            />
            <button onClick={makeRequest} disabled={requestLoading}>
              {requestLoading ? 'Loading...' : 'Submit'}
            </button>
          </div>
          {backendResult && (
            <div className="results">
              <h3>Result:</h3>
              <h4>Query for holders of tokens at {backendResult.date}</h4>
              <div>
                Token IDs:{' '}
                {backendResult.tokenIDs.map((tokenID, idx) => (
                  <span>
                    <a href={OPENSEA_TOKEN_URL + tokenID} target="_blank" rel="noopener noreferrer">
                      {tokenID}
                    </a>
                    {idx !== backendResult.tokenIDs.length - 1 && ', '}
                  </span>
                ))}
              </div>
              <div>
                <br />
                <br />
              </div>
              <div>
                <div style={{margin: '20px auto', display: 'flex'}}>
                  <div>
                    Base ETH to Send:{' '}
                    <input
                      type="text"
                      value={ethToSend}
                      style={{
                        width: '35px',
                        borderColor: ethToSendBorderColor,
                        borderWidth: ethToSendBorderColor ? '4px' : '',
                        borderStyle: 'solid',
                      }}
                      onChange={(e) => {
                        if (isNaN(e.target.value)) {
                          setETHToSendBorderColor('red');
                        } else {
                          setETHToSendBorderColor('');
                        }
                        setETHToSend(e.target.value);
                      }}
                    />
                    <button onClick={sendETHToAll} disabled={!ethToSend || isNaN(ethToSend)}>
                      Send To All
                    </button>
                  </div>
                  <div style={{minWidth: '20px'}} />
                  <div>
                    OpenSea TokenID to Send:{' '}
                    <input
                      type="text"
                      value={openSeaTokenIDToSend}
                      style={{
                        width: '200px',
                      }}
                      onChange={(e) => {
                        setOpenSeaTokenToSend(e.target.value);
                      }}
                    />
                    <button
                      onClick={sendOpenSeaTokenToAll}
                      disabled={!openSeaTokenIDToSend || openSeaTokenIDToSend.length < 10}
                    >
                      Send To All
                    </button>
                    <div style={{minWidth: '20px'}} />
                  </div>
                  <div>
                    <CSVLink
                      filename={`COD-holders.csv`}
                      data={backendResult.addresses.map((result) => ({
                        address: result.address,
                        count: result.count,
                      }))}
                    >
                      <button>Download CSV</button>
                    </CSVLink>
                  </div>
                </div>
                <table>
                  <tbody>
                    <tr>
                      <th>Address</th>
                      <th>Count</th>
                      <th></th>
                    </tr>
                    {backendResult.addresses.map((result) => (
                      <tr>
                        <td>{result.address}</td>
                        <td>{result.count}</td>
                        <td>
                          {statuses[result.address] ? (
                            statuses[result.address]
                          ) : (
                            <>
                              <button
                                style={{margin: 'auto'}}
                                disabled={!ethToSend || isNaN(ethToSend)}
                                onClick={() => sendETH(result.address, result.count)}
                              >
                                Send ETH
                              </button>{' '}
                              <button
                                style={{margin: 'auto'}}
                                disabled={!openSeaTokenIDToSend || openSeaTokenIDToSend.length < 10}
                                onClick={() => sendOpenSeaToken(result.address, result.count)}
                              >
                                Send OpenSea Token
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div>
                <br />
                <br />
              </div>
              <div>
                <h3>CSV</h3>
                <pre>{backendResult.addresses.map((result) => `${result.address},${result.count}\n`)}</pre>
              </div>
            </div>
          )}
          <div style={{marginBottom: 100}}></div>
        </div>
      );
    } else {
      return (
        <div style={{padding: '50px', textAlign: 'center'}}>
          <h3>Not authorized</h3>
          <p>{address}</p>
        </div>
      );
    }
  }

  // need connection
  return (
    <div style={{padding: '50px', textAlign: 'center'}}>
      <button onClick={connect}>Connect to MetaMask for Access</button>
    </div>
  );
};

export default Index;
