import React from 'react';
import {Link} from 'react-router-dom';
import {DiscordLogo, TwitterLogo} from '../../assets/icons/logos';
import banner from '../../assets/images/banner.jpg';
import clubs_court from '../../assets/images/clubs-court.jpg';
import diamonds_court from '../../assets/images/diamonds-court.jpg';
import epic_art from '../../assets/images/epic_art.jpg';
import fair_distro from '../../assets/images/fair_distro.gif';
import hearts_court from '../../assets/images/hearts-court.jpg';
import dodLogo from '../../assets/images/logo.jpg';
import map_all from '../../assets/images/map-all.jpg';
import outliers_court from '../../assets/images/outliers-court.jpg';
import spades_court from '../../assets/images/spades-court.jpg';
import special_trait from '../../assets/images/special-trait.jpg';
import {Team} from '../../components/team';
import {
  DISCORD_URL,
  OPENSEA_DODART_SERIES1_URL,
  OPENSEA_DODART_SERIES2_URL,
  OPENSEA_URL,
  TWITTER_URL,
} from '../../constants';
import './index.css';

const Home = () => {
  // const [mintCount, setMintCount] = useState(0);

  // const getMintCount = async () => {
  //   const response = await fetch('https://backend.deckofdegeneracy.com/cod_minted_count', {
  //     method: 'GET',
  //   });
  //   if (response.status === 200) {
  //     const result = await response.json();
  //     setMintCount(10000 - result.value);
  //   } else {
  //     console.error('cod_minted_count error', response);
  //     return;
  //   }
  // };
  // useEffect(() => {
  //   getMintCount();
  //   const intervalCall = setInterval(getMintCount, 5000);
  //   return () => {
  //     // clean up
  //     clearInterval(intervalCall);
  //   };
  // }, []);

  return (
    <>
      <div className="home-banner-container">
        <img src={banner} alt="Home Banner" />
      </div>

      <div className="home-container" id="about">
        <p className="text">
          Court of Degeneracy is a collection of 10,000 nfts on the Ethereum blockchain by Jonathan Little and Wes
          Henry. In addition to being an awesome profile picture, your Court of Degeneracy nft is also a membership
          token that gives you access to various potential real-world and online perks listed below. You can get your
          Court of Degeneracy pfp now on{' '}
          <a href={OPENSEA_URL} target="_blank" rel="noopener noreferrer">
            OpenSea
          </a>
          . Be sure to join our{' '}
          <a href={DISCORD_URL} target="_blank" rel="noopener noreferrer">
            Discord
          </a>{' '}
          and{' '}
          <a href={TWITTER_URL} target="_blank" rel="noopener noreferrer">
            follow us on Twitter
          </a>
        </p>

        {/* <hr className="gray-line mb-5"></hr>

        <div className="home">
          <h2 className="title">MINT{mintCount > 0 && <> - {mintCount.toLocaleString()} REMAIN</>}</h2>
          <Mint />
        </div> */}

        <hr className="gray-line mb-5"></hr>

        <h2 className="title">WHICH COURT DO YOU SIDE WITH?</h2>
        <div className="home-courts">
          <p className="text">
            In the beginning, there were five friends who lived in the Garden of Good Mornings. All was well for a
            while, and they ruled over their people in harmony, but one day the great earthquake cracked and changed the
            land in the garden. The five friends started fighting about who owned what and where. Fights turned to
            battles and battles turned to wars. Courts were created and titles were given. The Hearts, Spades, Clubs,
            and Diamonds set out to find a place to call their own while the Outliers stayed put in the desolated
            Garden.
          </p>
          <div className="court-map">
            <img src={map_all} alt="all courts map" />
          </div>
          <div className="court-links text">
            <div>
              <Link to={`/hearts/`}>The Court of Hearts</Link> made their way north and west to the volcanic Magma
              Mountains.
            </div>
            <div>
              <Link to={`/spades/`}>The Court of Spades</Link> went up The River Gamble and into the High Mountain.
            </div>
            <div>
              <Link to={`/diamonds/`}>The Court of Diamonds</Link> took to the east where they conquered the waves and
              turned it to ice.
            </div>
            <div>
              <Link to={`/clubs/`}>The Court of Clubs</Link> scattered into the wooded lands hoping to find whatever
              they were looking for.
            </div>
            <div>
              <Link to={`/outliers/`}>The Court of Outliers</Link> didn't know where to go and decided to stay put.
            </div>
            <br />
            <div>War still rages on, but only one Court will rule at the end in the Court of Degeneracy.</div>
          </div>
        </div>
        <div className="home">
          <div className="court-images">
            <Link to={`/hearts/`}>
              <img src={hearts_court} alt="hearts court" />
            </Link>
            <Link to={`/spades/`}>
              <img src={spades_court} alt="spades court" />
            </Link>
            <Link to={`/diamonds/`}>
              <img src={diamonds_court} alt="diamonds court" />
            </Link>
            <Link to={`/clubs/`}>
              <img src={clubs_court} alt="clubs court" />
            </Link>
            <Link to={`/outliers/`}>
              <img src={outliers_court} alt="outliers court" />
            </Link>
          </div>
        </div>

        <hr className="gray-line mb-5"></hr>

        <h2 className="title">SPECIAL UTILITY TRAITS</h2>
        <div className="home-side-by-side">
          <div>
            <p className="text">
              Many of the traits that make up the Court of Degeneracy nfts come with utility that will potentially be
              unlocked based on how well the project does. Nothing is guaranteed, but it would be pretty cool to:
            </p>
            <p className="text">Throw epic in-real-life parties in NYC, Vegas, and Denver</p>
            <p className="text">Provide 1-on-1 poker coaching with Jonathan Little</p>
            <p className="text">Deliver custom pfps and art airdrops from Wes Henry</p>
            <p className="text">Deliver nft airdrops from top artists</p>
            <p className="text">Create custom bottles of wine</p>
            <p className="text">Mail heavy boxes of tungsten cubes and decks of physical cards</p>
            <p className="text">Host 1eth freeroll poker tournaments</p>
            <p className="text">Do whatever else we think will be fun</p>
            <p className="text">
              Similar to Jonathan and Wes' previous nft project{' '}
              <a href="https://www.deckofdegeneracy.com/" target="_blank" rel="noreferrer">
                Deck of Degeneracy
              </a>{' '}
              where they gave holders over $1,000,000 in various forms of utility (poker tournament action, nft art, two
              epic parties, and physical items) in the project's first year, Court of Degeneracy will strive to deliver
              lots of value to holders as well.
            </p>
          </div>
          <div>
            <img src={special_trait} className="img2" alt="right" />
          </div>
        </div>

        <hr className="gray-line mb-5"></hr>

        <h2 className="title">MINTING DETAILS</h2>
        <div className="home-side-by-side">
          <div>
            <p className="text">You can mint any number of Court of Degeneracy nfts for .1eth each.</p>
            <p className="text">
              All Court of Degeneracy nfts will be distributed randomly, sight unseen. The art and traits of each nft
              are revealed as soon as they are obtained.
            </p>
            <p className="text">Good luck and have fun!</p>
          </div>
          <div>
            <img src={fair_distro} alt="Minting Details" />
          </div>
        </div>

        <hr className="gray-line mb-5"></hr>

        <h2 className="title">EPIC ART</h2>
        <div className="home-side-by-side">
          <p className="text">
            Court of Degeneracy will feature numerous rare traits provided by top artists in the nft space.{' '}
            <Link to="/traits/">Click here</Link> for a list of all traits provided by the artists and links to their
            twitter profiles.
            <br />
            <br />
            Holders of each of the 50 nfts that have the “Art” trait in the left hand will receive monthly airdrops for
            at least one year that will be part of the Degeneracy Art Collection{' '}
            <a href={OPENSEA_DODART_SERIES1_URL} target="_blank" rel="noreferrer">
              Series 1
            </a>{' '}
            and{' '}
            <a href={OPENSEA_DODART_SERIES2_URL} target="_blank" rel="noreferrer">
              Series 2
            </a>{' '}
            that features airdropped editions from some of the biggest names in the nft art space, as well as hot
            emerging artists.
            <br />
            <br />
            Holders of each of the five CUSTOM PFP nfts will be able to collaborate with Wes Henry to create a custom
            Court of Degeneracy profile picture as they see fit within reason.
          </p>
          <div>
            <img src={epic_art} alt="Epic Art" />
          </div>
        </div>
        <hr className="gray-line mb-5"></hr>
      </div>

      <Team />

      <div className="tail">
        <div className="tail-1">
          <a href="/">
            <img src={dodLogo} alt="Court of Degeneracy Logo" />
          </a>
        </div>
        <div className="tail-links">
          <a href={DISCORD_URL} target="_blank" rel="noopener noreferrer">
            <DiscordLogo />
          </a>{' '}
          <a href={TWITTER_URL} target="_blank" rel="noopener noreferrer">
            <TwitterLogo />
          </a>
        </div>
        <div className="tail-2">Copyright &copy; 2023 Court of Degeneracy. All Rights Reserved.</div>
      </div>
    </>
  );
};

export default Home;
