import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {BACKEND_URL} from '../../constants';
import {Web3Context} from '../../context/web3-context';
import './index.css';

const storageVersion = '1';

const defaultFormFields = {
  email: {
    name: 'Email',
    required: true,
    validation: (value) => {
      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        return 'Invalid Email';
      }
    },
  },
  name: {
    name: 'Name',
    required: true,
  },
  mailing_address_1: {
    name: 'Mailing Address 1',
    required: true,
  },
  mailing_address_2: {
    name: 'Mailing Address 2',
  },
  city: {
    name: 'City',
    required: true,
  },
  state: {
    name: 'State',
  },
  zip: {
    name: 'Zip/Postal Code',
  },
  country: {
    name: 'Country',
  },
};

const Forms = () => {
  const form = useParams()['form'];
  const [formValues, setFormValues] = useState(
    JSON.parse(sessionStorage.getItem(`form_${form}_${storageVersion}`) || '{}')
  );
  const [error, setError] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [submittedForm, setSubmittedForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const {address, connect, connected, web3} = useContext(Web3Context);

  function payloadToMessage(payload) {
    let lines = [];
    for (const [key, value] of Object.entries(payload)) {
      if (value) {
        lines.push(`${key}: ${value}`);
      }
    }
    return lines.join('\n');
  }

  async function makeSignedRequest(url, payload) {
    setLoading(true);
    payload = {...payload, form};
    const msg = payloadToMessage(payload);
    let signature;
    try {
      signature = await web3.eth.personal.sign(msg, address);
    } catch (error) {
      setError(error.message || 'signing error');
      setLoading(false);
      return;
    }
    const response = await fetch(`${BACKEND_URL}/${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        address,
        msg,
        form,
        payload,
        signature,
      }),
    });
    if (response.status !== 200) {
      const body = await response.text();
      if (body.includes("account doesn't hold required tokens")) {
        setError(`Address ${address} doesn't hold any of the required tokens`);
      } else {
        setError(`${response.status} error: Invalid Response\nBody: ${body}`);
      }
      setLoading(false);
      return;
    }
    const result = await response.json();
    setLoading(false);
    return result;
  }

  async function doSubmitForm() {
    setError('');
    setLoading(true);
    const result = await makeSignedRequest('form_response', formValues);
    if (result) {
      setSubmittedForm(true);
    }
  }

  async function doConnect() {
    setLoading(true);
    if (!connected) {
      await connect();
    }
  }

  let validFormCode = true;
  let title;
  let formBody;
  let formFields;
  switch (form) {
    case 'dod_physical_deck':
      title = 'Deck of Degeneracy - Physical Deck of Cards';
      formBody = (
        <div>
          Fill out this form to be mailed physical Deck of Degeneracy decks. (Holdings & Number of Decks will be
          validated)
        </div>
      );
      formFields = {
        ...defaultFormFields,
        number_of_decks: {
          name: 'Number of Decks',
          default: 1,
          required: true,
        },
      };
      break;
    default:
      validFormCode = false;
      break;
  }

  function validateInputs() {
    const updatedFormErrors = {};
    let valid = true;
    for (const [key, value] of Object.entries(formValues)) {
      if (formFields[key] && formFields[key].validation) {
        const error = formFields[key].validation(value);
        if (error) {
          updatedFormErrors[key] = error;
        }
      }
    }
    for (const [key, data] of Object.entries(formFields)) {
      if (data.required && !formValues[key]) {
        valid = false;
        updatedFormErrors[key] = `Required`;
      }
    }
    if (Object.entries(formErrors).toString() !== Object.entries(updatedFormErrors).toString()) {
      setFormErrors(updatedFormErrors);
    }
    return valid;
  }

  useEffect(() => {
    if (validFormCode && Object.keys(formValues).length === 0) {
      // fill in any default values
      const newFormValues = {...formValues};
      let update = false;
      for (const [key, data] of Object.entries(formFields)) {
        // trick place empty values so object message is in order for signing step
        if (!Object.hasOwn(formValues, key)) {
          update = true;
          if (data.default) {
            // set default
            newFormValues[key] = data.default;
          } else {
            // set empty
            newFormValues[key] = '';
          }
        }
      }
      if (update) {
        setFormValues(newFormValues);
      }
    }
  }, [formValues]);

  useEffect(() => {
    if (validFormCode) {
      validateInputs();
      sessionStorage.setItem(`form_${form}_${storageVersion}`, JSON.stringify(formValues));
    }
  }, [form, formValues]);

  useEffect(() => {
    if (connected && loading) {
      if (validateInputs()) {
        doSubmitForm();
      } else {
        setLoading(false);
      }
    }
  }, [connected, loading]);

  if (!validFormCode) {
    return (
      <>
        <div className="forms-container" id="forms">
          <>
            <h2>Form not found {form}</h2>
          </>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="forms-container" id="forms">
        <>
          <h1>{title}</h1>
          {error && (
            <div className="error">
              <h3>Error</h3>
              <pre>{error}</pre>
            </div>
          )}
          <div>
            {submittedForm ? (
              <>
                <h2>Form submitted successfully</h2>
                <Link to={`/`}>Home</Link>
              </>
            ) : (
              <>
                {formBody}
                <form>
                  {Object.entries(formFields).map(([key, data]) => (
                    <div key={key}>
                      <label htmlFor={key}>{data.name}:</label>
                      <input
                        id={key}
                        name={key}
                        value={formValues[key]}
                        disabled={loading}
                        onChange={(e) => {
                          const newValues = {...formValues};
                          newValues[key] = e.target.value;
                          setFormValues(newValues);
                        }}
                      />
                      {formErrors[key] && <span className="error">{formErrors[key]}</span>}
                    </div>
                  ))}
                </form>
                <button onClick={doConnect} disabled={loading || (formErrors && Object.keys(formErrors).length > 0)}>
                  {loading ? 'Submitting...' : 'Submit signed request'}
                </button>
              </>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default Forms;
