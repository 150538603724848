export const COD_SITE_URL = 'https://www.courtofdegeneracy.com';
export const DISCORD_URL = 'https://discord.gg/Dna6465WQn';
export const DOD_SITE_URL = 'https://www.deckofdegeneracy.com';
export const LOOKSRARE_URL = 'https://looksrare.org/collections/0x8b3C69aBc8c588E3ECeb2EeDf3bccf8FEA950241';
export const ONCYBER_URL = 'https://oncyber.io/degeneracy';
export const OPENSEA_DODART_SERIES1_URL = 'https://opensea.io/collection/dodart';
export const OPENSEA_DODART_SERIES2_URL = 'https://opensea.io/collection/dodart2';
export const OPENSEA_TOKEN_URL = 'https://opensea.io/assets/ethereum/0xfba2e4a2774d37610ef0f0111fd7c9728eddcbd8/';
export const OPENSEA_URL = 'https://opensea.io/collection/courtofdegeneracy';
export const PERKS_URL = 'https://docs.google.com/spreadsheets/d/1fmbGmAnwDMMtessNBryPlelh99Pa0pw_IPGeTt7piOE/';
export const TWITTER_URL = 'https://twitter.com/DegeneracyNFT';

export const WHITELIST_PRICE = 0.1;
export const WHITELIST_LIMIT = 54;
export const REGULAR_PRICE = 0.1;
export const REGULAR_LIMIT = 54;
export const MAX_MINT = 2699;

export const TESTING_MODE = Object.hasOwn(process.env, 'REACT_APP_TESTING_MODE')
  ? process.env.REACT_APP_TESTING_MODE.toLowerCase() === 'true'
  : false;
if (TESTING_MODE) {
  console.warn('In TESTING_MODE');
}
export const BACKEND_URL = TESTING_MODE ? 'http://localhost:8000' : 'https://backend.deckofdegeneracy.com';
