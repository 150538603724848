import {Route, Routes} from 'react-router-dom';
import Navbar from '../components/navbar';
import Admin from '../pages/admin';
import Art from '../pages/art';
import Courts from '../pages/courts';
import Home from '../pages/home';
import Traits from '../pages/traits';
import Forms from '../pages/forms';

const Pages = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="admin" element={<Admin />} />
        <Route path="art" element={<Art />} />
        <Route path="traits" element={<Traits />} />
        <Route path="clubs" element={<Courts.Clubs />} />
        <Route path="diamonds" element={<Courts.Diamonds />} />
        <Route path="hearts" element={<Courts.Hearts />} />
        <Route path="spades" element={<Courts.Spades />} />
        <Route path="outliers" element={<Courts.Outliers />} />
        <Route path="forms" element={<Forms />} />
        <Route path="forms/:form" element={<Forms />} />
      </Routes>
    </>
  );
};

export default Pages;
